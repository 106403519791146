/* General */
.custom-button {
	width: 200px;
	/* margin-right: 15px; */
	background: none;
	border: none;
}
.custom-button img {
	width: 100%;
}
.custom-button:hover img {
	scale: 1.03;
	transition: 0.8s;
}
.stake-button {
	width: 200px;
	margin-top: 30px;
	background: none;
	border: none;
}
.stake-button img {
	width: 100%;
}
.stake-button:hover img {
	scale: 1.03;
	transition: 0.8s;
}

/* Login */
.login-section {
	/* height: 120vh; */
	padding-top: 120px;
	padding-bottom: 120px;
}
@media (max-width: 992px) {
	.login-section {
		/* height: 100vh; */
		padding-top: 70px;
		padding-bottom: 70px;
	}
}
.login-logo {
	width: 180px;
}
@media (max-width: 992px) {
	.login-logo {
		width: 150px;
	}
}
.social-links i {
	margin: 0px 10px;
	font-size: 38px;
	color: #36d5e7;
	opacity: 0.7;
}
.social-links i:hover {
	scale: 1.05;
	transition: 0.8s;
	opacity: 1;
}

/* Header */
.header {
	padding: 30px 220px;
}
.header-logo {
	width: 220px;
}
.connect-button {
	background-color: #0d0b2d;
	border: 2px solid #36d5e7;
	color: #36d5e7;
	margin-top: -15px;
	padding: 10px 25px;
	font-weight: bold;
	letter-spacing: 2px;
}
.connect-button:hover {
	background-color: #36d5e7;
	color: #0d0b2d;
	transition: 0.8s;
}
.menu-button {
	background: none;
	border: none;
	padding-top: 5px;
}
.menu-button:hover {
	scale: 1.08;
	transition: 0.5s;
}
.menu-button span {
	font-size: 48px;
	color: #36d5e7;
}




/* Public Sale Section */
.public-sale-section {
	background: url(../img/hero-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
	padding: 80px 220px;
}
.token-stack {
	height: 160px;
}
.token-sale-supply {
	font-size: 42px;
	margin: 50px 15px;
}
.token-price {
	font-size: 32px;
}
.usdt-amount {
	padding: 15px;
	font-size: 22px;
}



/* About Section */
.about-section {
	padding: 100px 220px;
}




/* NFT Staking Section  */
.nft-staking-section {
	background-color: #000000;
	padding: 80px 220px;
}




/* Footer */
.footer {
	background-color: #000000;
	padding: 30px 220px;
}
@media (max-width: 992px) {
    .footer{padding: 20px 50px;}
}
.footer-logo {
	width: 180px;
}
.copyright-text {
	font-size: 12px;
	color: #c6c6c6;
}
.flip-countdown-title {
    color: white;
}











