@font-face {
    font-family: 'Ethnocentric';
    src: url('../fonts/ethnocentric_rg-webfont.woff2') format('woff2'),
         url('../fonts/ethnocentric_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-Regular.woff2') format('woff2'),
         url('../fonts/Outfit-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit-Bold';
    src: url('../fonts/Outfit-Bold.woff2') format('woff2'),
         url('../fonts/Outfit-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5, h6 {font-family: 'Ethnocentric'}
p {font-family: 'Outfit';}

h3 {font-size: 28px;}
p {font-size: 20px;}

.blue {color: #0a5791;}
.darkblue {color: #0d0b2d;}
.neonblue {color: #36d5e7;}

body {
    overflow-x: hidden;
    background-color: #0d0b2d;
}